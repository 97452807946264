import { placeActionType } from "./actionType";
import axios from "axios";
import { getToken } from "../../helper/localStograte";

const placeLoading = () => ({
  type: placeActionType.LOADING_PLACE,
});

const placeFetched = (payload: any) => ({
  type: placeActionType.FETCH_PLACE,
  payload,
});

const placeCreatedLoading = (payload: any) => ({
  type: placeActionType.LOADING_CREATE_PLACE,
});

const placeCreated = (payload: any) => ({
  type: placeActionType.CREATE_PLACE,
  payload,
});

const placeOneFetched = (payload: any) => ({
  type: placeActionType.FETCH_DETAIL_PLACE,
  payload,
});

const placeEditedLoading = () => ({
  type: placeActionType.LOADING_EDIT_PLACE,
});

const placeEdited = (payload: any) => ({
  type: placeActionType.EDITED_PLACE,
  payload,
});

const placeDeletedLoading = () => ({
  type: placeActionType.LOADING_DELETE_PLACE,
});

const placeDeleted = (payload: any) => ({
  type: placeActionType.DELETED_PLACE,
  payload,
});

export const fetchPlace =
  (params?: any, body?: any) => (dispatch: any, getState: any) => {
    return new Promise(async (resolve, reject) => {
      await dispatch(placeLoading());

      try {
        const place = await axios({
          url: `${process.env.REACT_APP_BASE_URL}/place/${params.slug}?name=${params.query.name}`,
          method: "get",
        });

        const { features } = place.data.data;

        const newFeatures = features.map((feature: { [key: string]: any }) => {
          return {
            ...feature,
            isActive: false,
          };
        });

        place.data.data.features = newFeatures;

        resolve(dispatch(placeFetched(place.data.data)));
      } catch (error: any) {
        console.log(error);
      }
    });
  };

export const fetchPlaceDetail =
  (params?: any, body?: any) => (dispatch: any, getState: any) => {
    return new Promise(async (resolve, reject) => {
      try {
        await dispatch(placeLoading());

        const place = await axios({
          url: `${process.env.REACT_APP_BASE_URL}/place/detail/${params.username}/${params.map}/${params.placename}`,
          method: "get",
        });

        place.data.data.isActive = false;

        resolve(dispatch(placeOneFetched(place.data.data)));
      } catch (error) {
        reject(error);
      }
    });
  };

export const createPlace =
  (params?: any, body?: any) => (dispatch: any, getState: any) => {
    return new Promise(async (resolve, reject) => {
      try {
        await dispatch(placeLoading());

        const place = await axios({
          url: `${process.env.REACT_APP_BASE_URL}/place`,
          method: "post",
          headers: {
            token: `${getToken()}`,
          },
          data: body,
        });

        place.data.data.isActive = false;

        resolve(dispatch(placeCreated(place.data.data)));
      } catch (error) {
        console.log(error);
      }
    });
  };

export const editPlace =
  (params?: any, body?: any) => (dispatch: any, getState: any) => {
    return new Promise(async (resolve, reject) => {
      try {
        await dispatch(placeEditedLoading());

        const place = await axios({
          url: `${process.env.REACT_APP_BASE_URL}/place/${params.id}`,
          method: "put",
          headers: {
            token: `${getToken()}`,
          },
          data: body,
        });

        place.data.data.isActive = false;

        resolve(dispatch(placeEdited(place.data.data)));
      } catch (error) {
        console.log(error);
      }
    });
  };

export const deletePlace =
  (params?: any, body?: any) => (dispatch: any, getState: any) => {
    return new Promise(async (resolve, reject) => {
      try {
        await dispatch(placeDeletedLoading());

        const place = await axios({
          url: `${process.env.REACT_APP_BASE_URL}/place/${params.id}`,
          method: "delete",
          headers: {
            token: `${getToken()}`,
          },
        });

        resolve(dispatch(placeDeleted(Number(place.data.data))));
      } catch (error) {
        console.log(error);
      }
    });
  };
