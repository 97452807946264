import { layerActionType } from "./actionType";

interface State {
  loadingLayer: boolean;
  loadingDeleteLayer: boolean;
  dataLayers: { [key: string]: any }[];
  layerFetched: boolean;
}

const initState: State = {
  loadingLayer: false,
  dataLayers: [],
  layerFetched: false,
  loadingDeleteLayer: false,
};

const loadingLayer = (state: State) => ({
  ...state,
  loadingLayer: true,
});

const loadingDeleteLayer = (state: State) => ({
  ...state,
  loadingDeleteLayer: true,
});

const fetchedLayer = (state: State, payload: any) => ({
  ...state,
  dataLayers: payload,
  layerFetched: true,
  loadingLayer: false,
});

const createdLayer = (state: State, payload: any) => ({
  ...state,
  dataLayers: [...state.dataLayers, payload],
  loadingLayer: false,
});

const editedLayer = (state: State, payload: any) => ({
  ...state,
  dataLayers: state.dataLayers.map((layer: { [key: string]: any }) => {
    if (payload.id === layer.id) {
      return payload;
    }
    return layer;
  }),
  loadingLayer: false,
});

const deletedLayer = (state: State, payload: any) => ({
  ...state,
  dataLayers: state.dataLayers.filter((layer: { [key: string]: any }) => {
    return payload.id !== layer.id;
  }),
  loadingDeleteLayer: false,
});

const layerReducer = (state = initState, action: any) => {
  switch (action.type) {
    case layerActionType.LOADING_LAYER:
      return loadingLayer(state);
    case layerActionType.LOADING_DELETE:
      return loadingDeleteLayer(state);
    case layerActionType.FETCH_LAYER:
      return fetchedLayer(state, action.payload);
    case layerActionType.CREATE_LAYER:
      return createdLayer(state, action.payload);
    case layerActionType.EDIT_LAYER:
      return editedLayer(state, action.payload);
    case layerActionType.DELETE_LAYER:
      return deletedLayer(state, action.payload);
    default:
      return state;
  }
};

export default layerReducer;
