import { applyMiddleware, createStore, combineReducers } from "redux";
import { routerMiddleware, connectRouter } from "connected-react-router";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
/** Reducer */
import userReducer from "./user/reducer";
import mapReducer from "./maps/redurcer";
import layerReducer from "./layers/reducer";
import placeReducer from "./place/reducer";

const rootReducer = combineReducers({
  user: userReducer,
  map: mapReducer,
  layer: layerReducer,
  place: placeReducer,
});

const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(thunk))
);

export default store;
