import React, { useState, useEffect } from "react";
/** Component */
import { Sidebar, Form, CardPlace } from "../../components";
/** Reeact Router */
import { useNavigate, useLocation } from "react-router-dom";
/** React Redux */
import { useDispatch, useSelector } from "react-redux";
/** Action */
import { resetMap, editMap, fetchMap } from "../../store/maps/action";
import { createLayer, editLayer } from "../../store/layers/action";
/** Swal */
import Swal from "sweetalert2";

interface Props {}

interface Inputs {
  type: string;
  placeholder: string;
  value: string | boolean | number;
  name: string;
  label: string;
  inputType: string;
  options?: { [key: string]: any }[];
}

const FormMap: React.FC<Props> = () => {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const location = useLocation();

  const layer = useSelector((state: any) => state.layer);

  const map = useSelector((state: any) => state.map);

  const { loadingLayer } = layer;

  const { dataMap, mapFetched } = map;

  const [inputs, setInputs] = useState<Inputs[]>([
    {
      type: "text",
      placeholder: "Please input your Layer name",
      value: "",
      name: "name",
      label: "Layer Name",
      inputType: "input",
    },
    {
      type: "text",
      placeholder: "Please input your Layer description",
      value: "",
      name: "description",
      label: "Layer Description",
      inputType: "textarea",
    },
    {
      type: "",
      placeholder: "Select which map you want to put layer on it",
      value: 0,
      name: "mapId",
      label: "Map",
      inputType: "select",
      options: [],
    },
  ]);

  const [purpose, setPurpose] = useState<string>("");

  useEffect(() => {
    if (!mapFetched) {
      (async () => {
        await dispatch(fetchMap());
      })();
    } else {
      const newInputs = [...inputs];
      newInputs[2].value = dataMap[0].id;
      newInputs[2].options = dataMap;
      setInputs(newInputs);
    }
  }, [mapFetched]);

  useEffect(() => {
    if (loadingLayer) {
      Swal.fire({
        title:
          purpose === "create" ? "Creating your Layer" : "Updating your Layer",
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });
    } else {
      Swal.close();
    }
  }, [loadingLayer]);

  useEffect(() => {
    const pathArray = location.pathname.split("/");
    const { state }: any = location;
    if (pathArray[1] === "edit-layer") {
      // TODO
      const newInputs = [...inputs];
      newInputs[0].value = state.name;
      newInputs[1].value = state.description;
      newInputs[2].value = state.mapId;
      setPurpose("edit");
      setInputs(newInputs);
    } else {
      setPurpose("create");
    }
  }, [location]);

  const handleCreateLayer = async () => {
    try {
      const body: { [key: string]: any } = {};

      inputs.forEach((input: { [key: string]: any }) => {
        body[input.name] = input.value;
      });

      await dispatch(createLayer(null, body));

      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
      });

      Toast.fire({
        icon: "success",
        title: "Map have been created",
      });

      navigate("/layers");
    } catch (error: any) {
      console.log("error");
    }
  };

  const handleEditMap = async () => {
    try {
      const { state }: any = location;

      const body: { [key: string]: any } = {};

      inputs.forEach((input: { [key: string]: any }) => {
        body[input.name] = input.value;
      });

      await dispatch(editLayer({ id: state.id }, body));

      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
      });

      Toast.fire({
        icon: "success",
        title: "Map have been updated",
      });

      navigate("/layers");
    } catch (error: any) {
      console.log(error);
    }
  };

  return (
    <div className="flex flex-wrap w-screen h-screen">
      <Sidebar />
      <div className="w-5/6 relative bg-gray-100">
        <div className="shadow-md w-full p-3">
          <h1 className="font-bold text-lg">
            {purpose === "create"
              ? "Create your own layer"
              : "Edit your own layer"}
          </h1>
        </div>
        <div className="p-10 w-3/5 flex flex-wrap mx-auto items-center">
          <CardPlace background>
            <div className="pb-6 pt-1 px-3">
              {mapFetched && (
                <Form
                  inputs={inputs}
                  setInputs={setInputs}
                  action={
                    purpose === "create" ? handleCreateLayer : handleEditMap
                  }
                  btnName={purpose === "create" ? "Save Layer" : "Edit Layer"}
                />
              )}
            </div>
          </CardPlace>
        </div>
      </div>
    </div>
  );
};

export default FormMap;
