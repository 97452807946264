import "mapbox-gl/dist/mapbox-gl.css";
import "react-map-gl-geocoder/dist/mapbox-gl-geocoder.css";
import React, {
  useState,
  useRef,
  useCallback,
  useEffect,
  useMemo,
} from "react";
import MapGL from "react-map-gl";
import Geocoder from "react-map-gl-geocoder";

interface Props {
  action: Function;
  dataGeo?: { lat: number; lng: number };
}

const MAPBOX_TOKEN = process.env.REACT_APP_MAPBOX_GL;

const CustomMapGeoCoder: React.FC<Props> = ({ action, dataGeo }) => {
  const [viewport, setViewport] = useState({
    latitude: 37.7577,
    longitude: -122.4376,
    zoom: 8,
  });
  const [inputGeoCoder, setInputGeoCoder] = useState<any>();
  const mapRef: any = useRef();
  const handleViewportChange = useCallback(
    (newViewport: any) => setViewport(newViewport),
    []
  );

  // if you are happy with Geocoder default settings, you can just use handleViewportChange directly
  const handleGeocoderViewportChange = useCallback(
    (newViewport: any) => {
      const geocoderDefaultOverrides = { transitionDuration: 1000 };

      return handleViewportChange({
        ...newViewport,
        ...geocoderDefaultOverrides,
      });
    },
    [handleViewportChange]
  );

  const handleResulChange = useCallback(
    (result: any) => action(result.result.center),
    []
  );

  return (
    <div style={{ height: "50vh" }}>
      <MapGL
        ref={mapRef}
        {...viewport}
        width="100%"
        height="100%"
        onViewportChange={handleViewportChange}
        mapboxApiAccessToken={MAPBOX_TOKEN}
        doubleClickZoom={false}
      >
        <Geocoder
          mapRef={mapRef}
          onViewportChange={handleGeocoderViewportChange}
          mapboxApiAccessToken={MAPBOX_TOKEN}
          onResult={handleResulChange}
          position="top-left"
        />
      </MapGL>
    </div>
  );
  // const [viewport, setViewport] = useState<{ [key: string]: any }>({
  //   latitude: dataGeo ? dataGeo.lat : 37.7577,
  //   longitude: dataGeo ? dataGeo.lng : -122.4376,
  //   zoom: 8,
  // });
  // const mapRef: any = useRef();
  // const handleViewportChange = useCallback(
  //   (newViewport: { [key: string]: any }) => setViewport(newViewport),
  //   []
  // );

  // useEffect(() => {
  //   if (dataGeo) {
  //     setViewport({
  //       latitude: dataGeo.lng,
  //       longitude: dataGeo.lat,
  //       zoom: 14.5,
  //     });
  //   }
  // }, [dataGeo]);

  // const handleGeocoderViewportChange = useCallback(
  //   (newViewport: { [key: string]: any }) => {
  //     const geocoderDefaultOverrides = { transitionDuration: 1000 };

  //     return handleViewportChange({
  //       ...newViewport,
  //       ...geocoderDefaultOverrides,
  //     });
  //   },
  //   [handleViewportChange]
  // );
  // return (
  //   <div className="h-80 w-full">
  //     <MapGL
  //       ref={mapRef}
  //       {...viewport}
  //       width="100%"
  //       height={"100%"}
  //       onViewportChange={handleGeocoderViewportChange}
  //       mapboxApiAccessToken={MAPBOX_TOKEN}
  //       mapStyle={"mapbox://styles/mapbox/light-v10"}
  //       doubleClickZoom={false}
  //     >
  //       <Geocoder
  //         mapRef={mapRef}
  //         onViewportChange={handleGeocoderViewportChange}
  //         mapboxApiAccessToken={MAPBOX_TOKEN}
  //         position="top-left"
  //         onResult={(e: any) => action(e.result.center)}
  //         marker={true}
  //         collapse={true}
  //         onError={(e: any) => console.log(e)}
  //       />
  //     </MapGL>
  //   </div>
  // );
};

export default CustomMapGeoCoder;
