import { userActionType } from "./actionType";

interface State {
  user: { [key: string]: any };
  loadingLogin: boolean;
  loadingRegister: boolean;
}

const initState: State = {
  user: {},
  loadingLogin: false,
  loadingRegister: false,
};

const loadingRegister = (state: State) => ({
  ...state,
  loadingRegister: true,
});

const registerSuccess = (state: State) => ({
  ...state,
  loadingRegister: false,
});

const resetRegister = (state: State) => ({
  ...state,
  loadingRegister: false,
  registerError: "",
});

const loadingLogin = (state: State) => ({
  ...state,
  loadingLogin: true,
});

const loginSuccess = (state: State, payload: any) => ({
  ...state,
  loadingLogin: false,
  setUser: payload,
});

const resetLogin = (state: State) => ({
  ...state,
  loadingLogin: false,
});

const userReducer = (
  state = initState,
  action: { type: string; payload: any }
) => {
  switch (action.type) {
    case userActionType.LOADING_REGISTER:
      return loadingRegister(state);
    case userActionType.REGISTER_SUCCESS:
      return registerSuccess(state);
    case userActionType.RESET_REGISTER:
      return resetRegister(state);
    case userActionType.LOADING_LOGIN:
      return loadingLogin(state);
    case userActionType.LOGIN_SUCCESS:
      return loginSuccess(state, action.payload);
    case userActionType.RESET_LOGIN:
      return resetLogin(state);
    default:
      return state;
  }
};

export default userReducer;
