import React, { useState, useEffect, useRef } from "react";
/** MapBox */
import mapboxgl from "mapbox-gl";
/** Turf */
import { bbox } from "@turf/turf";

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_GL
  ? process.env.REACT_APP_MAPBOX_GL
  : "";

interface Props {
  mapCenter: any;
  data: any;
}

const StaticMap: React.FC<Props> = ({ mapCenter, data }) => {
  const mapContainer = useRef<any>("");
  const [map, setMap] = useState<mapboxgl.Map>();

  useEffect(() => {
    setMap(
      new mapboxgl.Map({
        container: mapContainer.current,
        style: "mapbox://styles/mapbox/light-v10",
        doubleClickZoom: false,
        dragPan: false,
        dragRotate: false,
        center: mapCenter,
        zoom: 14,
      })
    );
  }, []);

  useEffect(() => {
    map?.scrollZoom.disable();

    map?.on("load", () => {
      map?.addSource("place-source", {
        type: "geojson",
        data: data,
      });

      map.addLayer({
        id: "places-layer",
        type: "circle",
        source: "place-source",
        paint: {
          "circle-color": "#2E97FC",
          "circle-radius": 10,
          "circle-stroke-width": 5,
          "circle-stroke-opacity": 0.05,
        },
      });
    });

    const fitBounds: any = bbox(data);

    map?.fitBounds(fitBounds, { padding: 200 });
  }, [map]);

  return <div ref={mapContainer} className="w-full h-full"></div>;
};

export default StaticMap;
