export enum mapActionType {
  LOADING_MAP = "LOADING_MAP",
  LOADING_DELETE = "LOADING_DELETE",
  MAP_FETCHED = "MAP_FETCHED",
  CREATE_MAP = "CREATE_MAP",
  RESET_MAP = "RESET_MAP",
  DELETE_MAP = "DELETE_MAP",
  EDIT_MAP = "EDIT_MAP",
  MAP_FETCHED_SINGLE = "MAP_FETCHED_SINGLE",
}
