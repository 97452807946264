import React, { useState, useEffect } from "react";
import classnames from "classnames";

interface Props {
  children: React.ReactNode;
  background?: boolean;
  from?: string;
}

const CardPlace: React.FC<Props> = ({ children, background, from }) => {
  return (
    <div
      className={classnames("shadow-lg w-full rounded", {
        ["bg-stone-200"]: background === false,
        ["bg-white"]: background,
        ["cursor-pointer"]: from,
        ["h-full"]: from,
        ["transition"]: from,
        ["ease-in-out"]: from,
        ["hover:-translate-y-1"]: from,
        ["hover:scale-105"]: from,
        ["duration-300"]: from,
      })}
    >
      {children}
    </div>
  );
};

export default CardPlace;
