import React, { useState, useEffect } from "react";

interface Props {
  children: React.ReactNode;
}

const Card: React.FC<Props> = ({ children }) => {
  return (
    <div className="shadow overflow-hidden sm:rounded-md bg-white px-4 py-4">
      {children}
    </div>
  );
};

export default Card;
