import React from "react";

interface Props {}

const NotFound: React.FC<Props> = () => {
  return (
    <div className="w-screen h-screen">
      <div className="flex flex-col w-full h-full justify-center items-center">
        <h1 className="text-7xl font-bold">404</h1>
        <h2 className="mt-3 font-semibold">
          Either Map is Private or it doesn't exist
        </h2>
      </div>
    </div>
  );
};

export default NotFound;
