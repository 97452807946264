import { placeActionType } from "./actionType";

interface State {
  loadingPlace: boolean;
  dataPlaces: { [key: string]: any };
  isPlaceFetched: boolean;
  dataPlace: { [key: string]: any };
  loadingCreatePlace: boolean;
  loadingEditedPlace: boolean;
  loadingDeletedPlace: boolean;
}

const initState: State = {
  loadingPlace: false,
  dataPlaces: {},
  isPlaceFetched: false,
  dataPlace: {},
  loadingCreatePlace: false,
  loadingEditedPlace: false,
  loadingDeletedPlace: false,
};

const placeLoading = (state: State) => ({
  ...state,
  loadingPlace: true,
});

const placeFetched = (state: State, payload: any) => ({
  ...state,
  dataPlaces: payload,
  loadingPlace: false,
  isPlaceFetched: true,
});

const placeLoadingCreated = (state: State) => ({
  ...state,
  loadingCreatePlace: true,
});

const placeCreated = (state: State, payload: any) => ({
  ...state,
  loadingCreatePlace: false,
  dataPlaces: {
    ...state.dataPlaces,
    features: [...state.dataPlaces.features, payload],
  },
});

const placeOneFetched = (state: State, payload: any) => ({
  ...state,
  loadingPlace: false,
  dataPlace: payload,
});

const placeLoadingEdited = (state: State) => ({
  ...state,
  loadingEditedPlace: true,
});

const placeEdited = (state: State, payload: any) => ({
  loadingEditedPlace: false,
  dataPlaces: {
    ...state.dataPlaces,
    features: state.dataPlaces.features.map(
      (feature: { [key: string]: any }) => {
        if (feature.properties.id === payload.properties.id) {
          return {
            ...payload,
          };
        }
        return {
          ...feature,
        };
      }
    ),
  },
});

const placeLoadingDeleted = (state: State) => ({
  ...state,
  loadingDeletedPlace: true,
});

const placeDeleleted = (state: State, payload: any) => ({
  loadingDeletedPlace: false,
  dataPlaces: {
    ...state.dataPlaces,
    features: state.dataPlaces.features.filter(
      (feature: { [key: string]: any }) => {
        return feature.properties.id !== payload;
      }
    ),
  },
});

const placeReducer = (state = initState, action: any) => {
  switch (action.type) {
    case placeActionType.LOADING_PLACE:
      return placeLoading(state);
    case placeActionType.FETCH_PLACE:
      return placeFetched(state, action.payload);
    case placeActionType.FETCH_DETAIL_PLACE:
      return placeOneFetched(state, action.payload);
    case placeActionType.LOADING_CREATE_PLACE:
      return placeLoadingCreated(state);
    case placeActionType.CREATE_PLACE:
      return placeCreated(state, action.payload);
    case placeActionType.LOADING_EDIT_PLACE:
      return placeLoadingEdited(state);
    case placeActionType.EDITED_PLACE:
      return placeEdited(state, action.payload);
    case placeActionType.LOADING_DELETE_PLACE:
      return placeLoadingDeleted(state);
    case placeActionType.DELETED_PLACE:
      return placeDeleleted(state, action.payload);
    default:
      return state;
  }
};

export default placeReducer;
