import React, { useState, useEffect } from "react";
/** React Rotuer */
import { useNavigate, Link, useParams } from "react-router-dom";
/** CustomMap */
import { CustomMap, CardPlace } from "../../components";
/** React Redux */
import { useSelector, useDispatch } from "react-redux";
/** Action */
import { fetchPlace } from "../../store/place/action";
/** Image */
import LoadingGif from "../../assets/loading.gif";
/** Swal */
import Swal from "sweetalert2";
/** classnames */
import classnames from "classnames";
/** Styles */
import "../../global.css";

interface Props {}

const UserMap: React.FC<Props> = () => {
  const { username, slug } = useParams();

  const dispatch = useDispatch();

  const map = useSelector((state: any) => state.map);

  const places = useSelector((state: any) => state.place);

  const { dataPlaces, loadingPlace, isPlaceFetched } = places;

  const { singleMap } = map;

  const [search, setSearch] = useState<string>("");

  const [dataGeo, setDataGeo] = useState<any>({});

  useEffect(() => {
    (async () => {
      await dispatch(fetchPlace({ slug, query: { name: search } }));
    })();
  }, [search]);

  useEffect(() => {
    if (!loadingPlace && !Object.keys(dataPlaces).length) {
      Swal.fire({
        title: "Please wait",
        text: "Fetching all the places",
        didOpen: () => {
          Swal.showLoading();
        },
      });
    } else {
      Swal.close();
    }
  }, [loadingPlace]);

  useEffect(() => {
    if (isPlaceFetched) {
      const newDataPlaces = { ...dataPlaces };
      setDataGeo(newDataPlaces);
    }
  }, [isPlaceFetched]);

  const handleCenterPlace = (id: number) => {
    const newDataGeo = { ...dataGeo };
    const { features } = newDataGeo;

    const newFeatures = features.map((feature: { [key: string]: any }) => {
      if (feature.properties.id === id) {
        return {
          ...feature,
          isActive: true,
        };
      } else {
        return {
          ...feature,
          isActive: false,
        };
      }
    });

    newDataGeo.features = newFeatures;

    setDataGeo(newDataGeo);
  };

  const renderPlaceList = (place: { [key: string]: any }, placeIdx: number) => {
    if (
      (!place.properties.isPublished &&
        username === localStorage.getItem("username")) ||
      place.properties.isPublished
    ) {
      return (
        <div className="w-full my-3 h-[120px]" key={placeIdx}>
          <CardPlace background={false} from={"map"}>
            <div
              className="flex flex-wrap w-full justify-between h-full"
              onClick={() => handleCenterPlace(place.properties.id)}
            >
              <img
                className="w-2/4 rounded-tl rounded-bl h-full"
                src={place.properties.image}
              />
              <div className="pr-2 pt-2 w-2/5">
                <p className="font-semibold text-amber-700 text-sm">
                  {place.properties.name}
                </p>
                <Link
                  to={`/${username}/${slug}/${place.properties.name}`}
                  state={place}
                  className="mt-2 underline font-medium text-blue-600 hover:text-blue-500 text-xs"
                >
                  Details
                </Link>
              </div>
            </div>
          </CardPlace>
        </div>
      );
    }
  };

  return isPlaceFetched && Object.keys(dataGeo).length > 0 ? (
    <div className="w-screen h-screen overflow-hidden">
      <div className="flex flex-wrap w-full h-full">
        <div className="w-1/4 p-3 bg-gray-50 dark:bg-gray-800 h-full shadow-md">
          <div
            className={classnames("overflow-y-auto no-scrollbar", {
              ["h-full"]:
                singleMap.ownerId !== Number(localStorage.getItem("id")),
              ["h-90"]:
                singleMap.ownerId === Number(localStorage.getItem("id")),
            })}
          >
            <input
              className="rounded shadow-md w-full p-2 mb-3"
              value={search}
              placeholder="Search Places"
              onChange={(e) => setSearch(e.target.value)}
            />
            <div className="flex flex-col">
              {dataPlaces &&
                dataPlaces.features.length > 0 &&
                dataPlaces.features.map(
                  (place: { [key: string]: any }, placeIdx: number) =>
                    renderPlaceList(place, placeIdx)
                )}
            </div>
          </div>
          {singleMap.ownerId == localStorage.getItem("id") && (
            <div className="h-10 w-full flex flex-wrap justify-center items-center">
              <Link
                to={`/${username}/${slug}/create-place`}
                className="w-full text-center py-1.5 rounded shadow-lg font-semibold text-white bg-indigo-600 hover:bg-indigo-hover"
              >
                Add Places
              </Link>
            </div>
          )}
        </div>
        <div className="w-3/4">
          <CustomMap
            height="100vh"
            data={dataPlaces}
            dataGeo={dataGeo}
            setDataGeo={setDataGeo}
          />
        </div>
      </div>
    </div>
  ) : (
    <div className="w-screen h-screen flex flex-col justify-center items-center">
      <img src={LoadingGif} />
      <h2>Please wait while the map is being fetched</h2>
    </div>
  );
};

export default UserMap;
