export enum placeActionType {
  LOADING_PLACE = "LOADING_PLACE",
  FETCH_PLACE = "FETCH_PLACE",
  CREATE_PLACE = "CREATE_PLACe",
  FETCH_DETAIL_PLACE = "FETCH_DETAIL_PLACE",
  LOADING_CREATE_PLACE = "LOADING_CREATE_PLACE",
  EDITED_PLACE = "EDITED_PLACE",
  LOADING_EDIT_PLACE = "LOADING_EDIT_MAP",
  DELETED_PLACE = "DELETED_PLACE",
  LOADING_DELETE_PLACE = "LOADING_DELETE_PLACE",
}
