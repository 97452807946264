import React, { useState, useEffect } from "react";
/** Component */
import { Sidebar, Form, CardPlace } from "../../components";
/** Reeact Router */
import { useNavigate, useLocation } from "react-router-dom";
/** React Redux */
import { useDispatch, useSelector } from "react-redux";
/** Action */
import { createMap, resetMap, editMap } from "../../store/maps/action";
/** Swal */
import Swal from "sweetalert2";

interface Props {}

interface Inputs {
  type: string;
  placeholder: string;
  value: string | boolean;
  name: string;
  label: string;
  inputType: string;
  subLabel?: { name: string; checked: boolean }[];
}

const FormMap: React.FC<Props> = () => {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const location = useLocation();

  const map = useSelector((state: any) => state.map);

  const { loadingMap } = map;

  const [inputs, setInputs] = useState<Inputs[]>([
    {
      type: "text",
      placeholder: "Please input your Map name",
      value: "",
      name: "name",
      label: "Map Name",
      inputType: "input",
    },
    {
      type: "text",
      placeholder: "Please input your URL for your map",
      value: "",
      name: "slug",
      label: "URL Slug",
      inputType: "input",
    },
    {
      type: "radio",
      placeholder: "",
      value: false,
      name: "isPublic",
      label: "Map Status",
      inputType: "radio",
      subLabel: [
        {
          name: "Public",
          checked: false,
        },
        {
          name: "Private",
          checked: false,
        },
      ],
    },
  ]);

  const [purpose, setPurpose] = useState<string>("");

  useEffect(() => {
    if (loadingMap) {
      Swal.fire({
        title: purpose === "create" ? "Creating your map" : "Updating your map",
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });
    } else {
      Swal.close();
    }
  }, [loadingMap]);

  useEffect(() => {
    const pathArray = location.pathname.split("/");
    const { state }: any = location;
    if (pathArray[1] === "edit-map") {
      // TODO
      const newInputs = [...inputs];
      newInputs[0].value = state.name;
      newInputs[1].value = state.slug;
      newInputs[2].value = state.isPublic;
      // TODO
      if (state.isPublic && newInputs[2].subLabel) {
        newInputs[2].subLabel[0].checked = true;
      } else if (!state.isPublic && newInputs[2].subLabel) {
        newInputs[2].subLabel[1].checked = true;
      }
      setPurpose("edit");
      setInputs(newInputs);
    } else {
      setPurpose("create");
    }
  }, [location]);

  const handleCreateMap = async () => {
    try {
      const body: { [key: string]: any } = {};

      inputs.forEach((input: { [key: string]: any }) => {
        body[input.name] = input.value;
      });

      await dispatch(createMap(null, body));

      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
      });

      Toast.fire({
        icon: "success",
        title: "Map have been created",
      });

      navigate("/");
    } catch (error: any) {
      Swal.close();

      dispatch(resetMap());

      Swal.fire({
        icon: "error",
        title: "Failed to create a new map",
        text: error,
      });
    }
  };

  const handleEditMap = async () => {
    try {
      const { state }: any = location;

      const body: { [key: string]: any } = {};

      inputs.forEach((input: { [key: string]: any }) => {
        body[input.name] = input.value;
      });

      await dispatch(editMap({ id: state.id }, body));

      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
      });

      Toast.fire({
        icon: "success",
        title: "Map have been updated",
      });

      navigate("/");
    } catch (error: any) {
      Swal.close();

      dispatch(resetMap());

      Swal.fire({
        icon: "error",
        title: "Failed to edit the map",
        text: error,
      });
    }
  };

  return (
    <div className="flex flex-wrap w-screen h-screen">
      <Sidebar />
      <div className="w-5/6 relative bg-gray-100">
        <div className="shadow-md w-full p-3">
          <h1 className="font-bold text-lg">
            {purpose === "create" ? "Create your own map" : "Edit your own map"}
          </h1>
        </div>
        <div className="p-10 w-3/5 flex flex-wrap mx-auto items-center">
          <CardPlace background>
            <div className="pb-6 pt-1 px-3">
              <Form
                inputs={inputs}
                setInputs={setInputs}
                action={purpose === "create" ? handleCreateMap : handleEditMap}
                btnName={purpose === "create" ? "Save Map" : "Edit Map"}
              />
            </div>
          </CardPlace>
        </div>
      </div>
    </div>
  );
};

export default FormMap;
