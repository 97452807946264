import React, { useState, useEffect } from "react";
/** Components */
import { Card, Form } from "../../components";
/** React Router */
import { Link, useNavigate } from "react-router-dom";
/** Action */
import { loginUser, resetLogin } from "../../store/user/action";
/** React Redux */
import { useDispatch, useSelector } from "react-redux";
/** Swal */
import Swal from "sweetalert2";

interface Props {}

interface Inputs {
  type: string;
  placeholder: string;
  value: string;
  name: string;
  label: string;
  inputType: string;
}

const Login: React.FC<Props> = () => {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const userLogin = useSelector((state: any) => state.user);

  const { loadingLogin, loginSuccess } = userLogin;

  const [inputs, setInputs] = useState<Inputs[]>([
    {
      label: "Email",
      type: "email",
      value: "",
      name: "email",
      placeholder: "Input your email here",
      inputType: "input",
    },
    {
      label: "Password",
      type: "password",
      value: "",
      name: "password",
      placeholder: "Input your password here",
      inputType: "input",
    },
  ]);

  useEffect(() => {
    if (loadingLogin) {
      Swal.fire({
        title: "Logging in",
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });
    }
  }, [loadingLogin]);

  const handleLogin = async () => {
    try {
      const body: { [key: string]: any } = {};

      inputs.forEach((input: Inputs) => {
        body[input.name] = input.value;
      });

      await dispatch(loginUser(null, body));

      Swal.close();

      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
      });

      Toast.fire({
        icon: "success",
        title: "Login Successful",
      });

      navigate("/");
    } catch (error: any) {
      Swal.fire({
        icon: "error",
        title: "Login Failed",
        text: error,
      });
    }
  };

  return (
    <div className="w-screen h-screen bg-gray-100">
      <div className="flex flex-wrap justify-center items-center w-full h-full">
        <div className="columns-md">
          <Card>
            <h1 className="text-center font-semibold text-xl">
              Welcome to Make Atlas
            </h1>
            <div className="mt-1">
              <Form
                inputs={inputs}
                setInputs={setInputs}
                action={handleLogin}
                btnName="LOGIN"
              />
            </div>
            <p className="text-center text-sm mt-3">
              Don't have an account ?{" "}
              <Link
                className="font-semibold text-indigo-600 hover:text-indigo-500"
                to="/register"
              >
                Register Here !
              </Link>
            </p>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default Login;
