import React, { useState, useEffect } from "react";

interface Props {
  inputs: { [key: string]: any }[];
  setInputs: React.Dispatch<React.SetStateAction<any>>;
  action: () => void;
  btnName: string;
}

const Form: React.FC<Props> = ({ inputs, setInputs, action, btnName }) => {
  const handleChange = (name: string, value: string | number | boolean) => {
    const newInputs = inputs.map(
      (input: { [key: string]: any }, inputIdx: number) => {
        if (input.name === name) {
          if (input.subLabel) {
            return {
              ...input,
              value,
              subLabel: [
                {
                  name: "Public",
                  checked: value === true ? true : false,
                },
                {
                  name: "Private",
                  checked: value === false ? true : false,
                },
              ],
            };
          } else {
            return {
              ...input,
              value,
            };
          }
        }

        return input;
      }
    );

    setInputs(newInputs);
  };

  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    action();
  };

  const renderInput = (input: { [key: string]: any }, inputIdx: number) => (
    <div className="w-full my-2" key={inputIdx}>
      <label className="block text-sm font-medium text-gray-700">
        {input.label}
      </label>
      <input
        className="mt-2 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
        name={input.name}
        value={input.value}
        placeholder={input.placeholder}
        type={input.type}
        required
        onChange={(e: React.FormEvent<HTMLInputElement>) =>
          handleChange(e.currentTarget.name, e.currentTarget.value)
        }
      />
    </div>
  );

  const renderRadio = (input: { [key: string]: any }, inputIdx: number) => (
    <div className="w-full my-2" key={inputIdx}>
      <label className="block text-sm font-medium text-gray-700">
        {input.label}
      </label>
      <div className="flex flex-wrap w-full my-3">
        {input.subLabel.map(
          (label: { name: string; checked: boolean }, labelIdx: number) => (
            <div className="flex flex-col mr-5" key={labelIdx}>
              <label className="text-sm antialiased font-normal block mb-1">
                {label.name}
              </label>
              <input
                className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 mt-3 mx-auto"
                name={input.name}
                value={input.value}
                placeholder={input.placeholder}
                type={input.type}
                required
                checked={label.checked === true}
                onChange={(e: React.FormEvent<HTMLInputElement>) =>
                  handleChange(
                    e.currentTarget.name,
                    label.name === "Public" ? true : false
                  )
                }
              />
            </div>
          )
        )}
      </div>
    </div>
  );

  const renderSelect = (input: { [key: string]: any }, inputIdx: number) => (
    <div className="w-full my-2" key={inputIdx}>
      <label className="block text-sm font-medium text-gray-700">
        {input.label}
      </label>
      <select
        className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm mt-2"
        required
        name={input.name}
        value={input.value}
        onChange={(e: React.FormEvent<HTMLSelectElement>) =>
          handleChange(e.currentTarget.name, e.currentTarget.value)
        }
      >
        {input.options.map((map: { [key: string]: any }) => (
          <option value={map.id}>{map.name}</option>
        ))}
      </select>
    </div>
  );

  const renderTextArea = (input: { [key: string]: any }, inputIdx: number) => (
    <div className="w-full my-2" key={inputIdx}>
      <label className="block text-sm font-medium text-gray-700">
        {input.label}
      </label>
      <textarea
        className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md mt-2"
        name={input.name}
        value={input.value}
        placeholder={input.placeholder}
        rows={4}
        required
        onChange={(e: React.FormEvent<HTMLTextAreaElement>) =>
          handleChange(e.currentTarget.name, e.currentTarget.value)
        }
      />
    </div>
  );

  return (
    <form className="w-full flex flex-col px-5" onSubmit={handleSubmit}>
      {inputs.map((input: { [key: string]: any }, inputIdx: number) =>
        input.inputType === "input"
          ? renderInput(input, inputIdx)
          : input.inputType === "select"
          ? renderSelect(input, inputIdx)
          : input.inputType === "textarea"
          ? renderTextArea(input, inputIdx)
          : renderRadio(input, inputIdx)
      )}
      <button
        type="submit"
        className="
            bg-white 
            hover:bg-indigo-600
            hover:transition-[background-color] 
            transition duration-700 ease-in-out 
            py-1.5 
            px-2 
            rounded 
            mt-3 
            border 
            border-indigo-600
            font-bold
            tracking-wider
            hover:text-white
            hover:transition-[color]
            text-indigo-500"
      >
        {btnName}
      </button>
    </form>
  );
};

export default Form;
