import React, { useState, useEffect } from "react";
import { Routes, Route } from "react-router-dom";
/** Screens */
import {
  Login,
  Register,
  Dashboard,
  FormMap,
  Layer,
  FormLayer,
  UserMap,
  NotFound,
  FormPlace,
  PlaceDetail,
} from "./screens";
/** Components */
import { Form, ProtectedRoute, ProtectedRouteMap } from "./components";
/** React Redux */
import { useSelector } from "react-redux";
/** Styles */
import "./global.css";

const App: React.FC = () => {
  const user = useSelector((state: any) => state.user);

  let token: any;

  if (localStorage.getItem("token")) {
    token = localStorage.getItem("token");
  } else {
    token = user.user.token;
  }

  return (
    <>
      <Routes>
        <Route path="/register" element={<Register />}></Route>
        <Route path="/login" element={<Login />}></Route>
        <Route
          path="/"
          element={
            <ProtectedRoute token={token}>
              <Dashboard />
            </ProtectedRoute>
          }
        ></Route>
        <Route
          path="/create-map"
          element={
            <ProtectedRoute token={token}>
              <FormMap />
            </ProtectedRoute>
          }
        ></Route>
        <Route
          path="/edit-map/:id"
          element={
            <ProtectedRoute token={token}>
              <FormMap />
            </ProtectedRoute>
          }
        ></Route>
        <Route
          path="/layers"
          element={
            <ProtectedRoute token={token}>
              <Layer />
            </ProtectedRoute>
          }
        ></Route>
        <Route
          path="/create-layer"
          element={
            <ProtectedRoute token={token}>
              <FormLayer />
            </ProtectedRoute>
          }
        ></Route>
        <Route
          path="/edit-layer/:id"
          element={
            <ProtectedRoute token={token}>
              <FormLayer />
            </ProtectedRoute>
          }
        ></Route>
        <Route
          path={`/:username/:slug`}
          element={
            <ProtectedRouteMap>
              <UserMap />
            </ProtectedRouteMap>
          }
        ></Route>
        <Route
          path={`/:username/:slug/create-place`}
          element={
            <ProtectedRouteMap>
              <FormPlace></FormPlace>
            </ProtectedRouteMap>
          }
        ></Route>
        <Route
          path={`/:username/:slug/:placename`}
          element={
            <ProtectedRouteMap>
              <PlaceDetail></PlaceDetail>
            </ProtectedRouteMap>
          }
        ></Route>
        <Route
          path={`/:username/:slug/edit/:placename`}
          element={
            <ProtectedRouteMap>
              <FormPlace></FormPlace>
            </ProtectedRouteMap>
          }
        ></Route>
        <Route path="*" element={<NotFound />}></Route>
      </Routes>
    </>
  );
};

export default App;
