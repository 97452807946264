import axios from "axios";
import { layerActionType } from "./actionType";
import { getToken } from "../../helper/localStograte";

const loadingLayer = () => ({
  type: layerActionType.LOADING_LAYER,
});

const loadingDeleteLayer = () => ({
  type: layerActionType.LOADING_DELETE,
});

const layerFetched = (payload: any) => ({
  type: layerActionType.FETCH_LAYER,
  payload,
});

const layerCreated = (payload: any) => ({
  type: layerActionType.CREATE_LAYER,
  payload,
});

const layerEdited = (payload: any) => ({
  type: layerActionType.EDIT_LAYER,
  payload,
});

const layerDeleted = (payload: any) => ({
  type: layerActionType.DELETE_LAYER,
  payload,
});

export const fetchLayer =
  (params?: any, body?: any) => (dispatch: any, getState: any) => {
    return new Promise(async (resolve, reject) => {
      try {
        await dispatch(loadingLayer());

        const layer = await axios({
          url: `${process.env.REACT_APP_BASE_URL}/layer`,
          method: "get",
          headers: {
            token: `${getToken()}`,
          },
        });

        resolve(dispatch(layerFetched(layer.data.data)));
      } catch (error) {
        console.log(error);
      }
    });
  };

export const createLayer =
  (params?: any, body?: any) => (dispatch: any, getState: any) => {
    return new Promise(async (resolve, reject) => {
      try {
        await dispatch(loadingLayer());

        const layer = await axios({
          url: `${process.env.REACT_APP_BASE_URL}/layer`,
          method: "post",
          headers: {
            token: `${getToken()}`,
          },
          data: body
        });

        resolve(dispatch(layerCreated(layer.data.data)));
      } catch (error) {
        console.log(error);
      }
    });
  };

export const editLayer =
  (params?: any, body?: any) => (dispatch: any, getState: any) => {
    return new Promise(async (resolve, reject) => {
      try {
        await dispatch(loadingLayer());

        const layer = await axios({
          url: `${process.env.REACT_APP_BASE_URL}/layer/${params.id}`,
          method: "put",
          headers: {
            token: `${getToken()}`,
          },
          data: body,
        });

        resolve(dispatch(layerEdited(layer.data.data)));
      } catch (error) {
        console.log(error);
      }
    });
  };

export const deleteLayer =
  (params?: any, body?: any) => (dispatch: any, getState: any) => {
    return new Promise(async (resolve, reject) => {
      try {
        await dispatch(loadingDeleteLayer());

        const layer = await axios({
          url: `${process.env.REACT_APP_BASE_URL}/layer/${params.id}`,
          method: "delete",
          headers: {
            token: `${getToken()}`,
          },
        });

        resolve(dispatch(layerDeleted(layer.data.data)));
      } catch (error) {
        console.log(error);
      }
    });
  };
