import React, { useEffect, useState } from "react";
/** React Router */
import { Link, Navigate, useNavigate } from "react-router-dom";
/** React Redux */
import { useDispatch } from "react-redux";
/** Action */
import { resetLogin } from "../../store/user/action";
/** FontAwesome */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMap,
  faLayerGroup,
  faRightFromBracket,
} from "@fortawesome/free-solid-svg-icons";

interface Props {}

const Sidebar: React.FC<Props> = () => {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const handleChangeRoute = (route: string) => {
    navigate(route);
  };

  const handleLogOut = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("email");
    localStorage.removeItem("id");
    localStorage.removeItem("username");
    dispatch(resetLogin());

    navigate("/login");
  };

  return (
    <div className="w-2/12 h-full">
      <div className="container w-full h-full bg-gray-50 dark:bg-gray-800 pt-3 pb-2 px-2 shadow-md relative">
        <h1 className="font-bold text-white text-2xl tracking-wide px-1">
          Make Atlas
        </h1>
        <ul className="mt-5 flex flex-col justify-between h-5/6	">
          <div className="">
            <li
              className="flex items-center px-2 py-2.5 rounded-lg hover:bg-gray-100 dark:hover:bg-gray-700 cursor-pointer my-4"
              onClick={() => handleChangeRoute("/")}
            >
              <FontAwesomeIcon
                icon={faMap}
                className="mr-2.5"
                fontSize={"1.5rem"}
                color={"white"}
              />
              <span className="text-center font-semibold text-gray-900 dark:text-white text-lg">
                Dashboard
              </span>
            </li>
            <li
              className="flex items-center px-2 py-2.5 rounded-lg hover:bg-gray-100 dark:hover:bg-gray-700 cursor-pointer my-4"
              onClick={() => handleChangeRoute("/layers")}
            >
              <FontAwesomeIcon
                icon={faLayerGroup}
                className="mr-2.5"
                fontSize={"1.5rem"}
                color={"white"}
              />
              <span className="text-center font-semibold text-white text-lg">
                Layers
              </span>
            </li>
          </div>
          <li
            className="flex items-center px-2 py-2.5 rounded-lg hover:bg-gray-100 dark:hover:bg-gray-700 cursor-pointer"
            onClick={handleLogOut}
          >
            <FontAwesomeIcon
              icon={faRightFromBracket}
              className="mr-2.5"
              fontSize={"1.5rem"}
              color={"white"}
            />
            <span className="text-center font-semibold text-white text-lg">
              Logout
            </span>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Sidebar;
