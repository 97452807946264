import { userActionType } from "./actionType";
import axios from "axios";

const loadingRegister = () => ({
  type: userActionType.LOADING_REGISTER,
});

const registerSuccess = () => ({
  type: userActionType.REGISTER_SUCCESS,
});

export const resetRegister = () => ({
  type: userActionType.RESET_REGISTER,
});

const loadingLogin = () => ({
  type: userActionType.LOADING_LOGIN,
});

const loginSuccess = (payload: any) => ({
  type: userActionType.LOGIN_SUCCESS,
  payload,
});

export const resetLogin = () => ({
  type: userActionType.RESET_LOGIN,
});

export const createUser =
  (params?: any, body?: any) => (dispatch: any, getState: any) => {
    return new Promise(async (resolve, reject) => {
      try {
        await dispatch(loadingRegister());

        const newUser = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/user/register`,
          body
        );

        resolve(dispatch(registerSuccess()));
      } catch (error: any) {
        const { message } = error.response.data;
        reject(message);
      }
    });
  };

export const loginUser =
  (params?: any, body?: any) => (dispatch: any, getState: any) => {
    return new Promise(async (resolve, reject) => {
      try {
        await dispatch(loadingLogin());

        const user = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/user/login`,
          body
        );

        localStorage.setItem("token", user.data.data.token);
        localStorage.setItem("email", user.data.data.userEmail);
        localStorage.setItem("id", user.data.data.userId);
        localStorage.setItem("username", user.data.data.username);

        const reduxUser = {
          token: user.data.data.token,
          userEmail: user.data.data.userEmail,
          id: user.data.data.id,
          username: user.data.data.username,
        };

        resolve(dispatch(loginSuccess(reduxUser)));
      } catch (error: any) {
        const { errors } = error.response.data;
        reject(errors);
      }
    });
  };
