import React, { useEffect, useState, Fragment } from "react";
/** Components */
import { Sidebar, CardPlace, Card } from "../../components";
/** React Router */
import { Link } from "react-router-dom";
/** React Redux */
import { useDispatch, useSelector } from "react-redux";
/** Actions */
import { fetchMap, deleteMap } from "../../store/maps/action";
/** Swal */
import Swal from "sweetalert2";
/** Image */
import LoadingGif from "../../assets/loading.gif";
/** Menu */
import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon, ChevronRightIcon } from "@heroicons/react/solid";

interface Props {}

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

const Dashboard: React.FC<Props> = () => {
  const dispatch = useDispatch();

  const maps = useSelector((state: any) => state.map);

  const { dataMap, loadingMap, mapFetched, loadingDeleteMap } = maps;

  useEffect(() => {
    if (!mapFetched) {
      (async () => {
        await dispatch(fetchMap());
      })();
    }
  }, []);

  useEffect(() => {
    if (loadingDeleteMap) {
      Swal.fire({
        title: "Deleting your map",
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });
    } else {
      Swal.close();
    }
  }, [loadingDeleteMap]);

  const handleDeleteMap = async (id: number) => {
    try {
      await dispatch(deleteMap({ id }));

      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
      });

      Toast.fire({
        icon: "success",
        title: "Map have been deleted",
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="flex flex-wrap w-screen h-screen">
      <Sidebar />
      <div className="w-5/6 relative bg-gray-100">
        <div className="shadow-md w-full p-3 bg-white">
          <h1 className="font-bold text-lg">List of Maps</h1>
        </div>
        <div className="p-10">
          <CardPlace background>
            <div className="px-7 py-6">
              {mapFetched ? (
                <>
                  <Link
                    to={`/create-map`}
                    className="
                    shadow-md 
                    py-1.5 
                    px-5 
                    rounded 
                    font-semibold 
                    hover:bg-neutral-800 
                    hover:text-white
                    text-black 
                    border 
                    border-black"
                  >
                    Create Map
                  </Link>
                  <table className="table-auto not-prose bg-slate-50 rounded overflow-hidden dark:bg-slate-800/25 w-full mt-5">
                    <thead className="border-b bg-gray-800">
                      <tr className="border-b border-black">
                        <th
                          scope="col"
                          className="font-bold text-white px-6 py-4"
                        >
                          No
                        </th>
                        <th
                          scope="col"
                          className="font-bold text-white px-6 py-4"
                        >
                          Name
                        </th>
                        <th
                          scope="col"
                          className="font-bold text-white px-6 py-4"
                        >
                          URL Slug
                        </th>
                        <th
                          scope="col"
                          className="font-bold text-white px-6 py-4"
                        >
                          Status
                        </th>
                        <th
                          scope="col"
                          className="font-bold text-white px-6 py-4"
                        >
                          Action
                        </th>
                      </tr>
                    </thead>
                    <tbody className="z-0">
                      {dataMap.map(
                        (map: { [key: string]: any }, mapIdx: number) => (
                          <tr key={mapIdx}>
                            <td className="text-center py-5 border-b border-grey-100 text-base font-semibold">
                              {mapIdx + 1}
                            </td>
                            <td className="text-center py-5 border-b border-grey-100 text-base font-semibold">
                              {map.name}
                            </td>
                            <td className="text-center py-5 border-b border-grey-100 text-base font-semibold">
                              {map.slug}
                            </td>
                            <td className="text-center py-5 border-b border-grey-100 text-base font-semibold">
                              {map.isPublic ? "Public" : "Private"}
                            </td>
                            <td className="text-center border-b border-grey-100 relative py-5">
                              <Menu
                                as="div"
                                className="relative inline-block text-left"
                              >
                                <div>
                                  <Menu.Button className="inline-flex justify-center w-full rounded-md border border-indigo-600 shadow-sm px-4 py-2 bg-indigo-600 text-sm font-medium text-gray-700 hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 text-white">
                                    Options
                                    <ChevronDownIcon
                                      className="-mr-1 ml-2 h-5 w-5"
                                      aria-hidden="true"
                                    />
                                  </Menu.Button>
                                </div>

                                <Transition
                                  as={Fragment}
                                  enter="transition ease-out duration-100"
                                  enterFrom="transform opacity-0 scale-95"
                                  enterTo="transform opacity-100 scale-100"
                                  leave="transition ease-in duration-75"
                                  leaveFrom="transform opacity-100 scale-100"
                                  leaveTo="transform opacity-0 scale-95"
                                >
                                  <Menu.Items className="origin-top-right absolute top-0 w-full rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none z-10">
                                    <div className="py-1">
                                      <Menu.Item>
                                        {({ active }) => (
                                          <Link
                                            to={`/edit-map/${map.id}`}
                                            state={map}
                                            className={classNames(
                                              active
                                                ? "bg-gray-100 text-gray-900"
                                                : "text-gray-700",
                                              "block px-4 py-2 text-sm"
                                            )}
                                          >
                                            Edit
                                          </Link>
                                        )}
                                      </Menu.Item>
                                    </div>
                                    <div className="py-1">
                                      <Menu.Item>
                                        {({ active }) => (
                                          <a
                                            href="#"
                                            onClick={() =>
                                              handleDeleteMap(map.id)
                                            }
                                            className={classNames(
                                              active
                                                ? "bg-gray-100 text-gray-900"
                                                : "text-gray-700",
                                              "block px-4 py-2 text-sm"
                                            )}
                                          >
                                            Delete
                                          </a>
                                        )}
                                      </Menu.Item>
                                    </div>
                                  </Menu.Items>
                                </Transition>
                              </Menu>
                            </td>
                          </tr>
                        )
                      )}
                    </tbody>
                  </table>
                </>
              ) : (
                <div className="flex flex-col w-full h-full justify-center items-center">
                  <img src={LoadingGif} />
                  <h1 className="font-bold">Loading Map List</h1>
                </div>
              )}
            </div>
          </CardPlace>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
