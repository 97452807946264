import { stat } from "fs";
import { mapActionType } from "./actionType";

interface State {
  dataMap: { [key: string]: any }[];
  loadingMap: boolean;
  mapFetched: boolean;
  loadingDeleteMap: boolean;
  singleMap: { [key: string]: any };
}

const initState: State = {
  dataMap: [],
  loadingMap: false,
  mapFetched: false,
  loadingDeleteMap: false,
  singleMap: {},
};

const loadingMap = (state: State) => ({
  ...state,
  loadingMap: true,
});

const loadingDeleteMap = (state: State) => ({
  ...state,
  loadingDeleteMap: true,
});

const mapFetched = (state: State, payload: any) => ({
  ...state,
  dataMap: payload,
  mapFetched: true,
  loadingMap: false,
});

const mapOneFetched = (state: State, payload: any) => ({
  ...state,
  singleMap: payload,
  loadingMap: false,
});

const mapCreated = (state: State, payload: any) => ({
  ...state,
  dataMap: [...state.dataMap, payload],
  loadingMap: false,
});

const mapEdit = (state: State, payload: any) => ({
  ...state,
  loadingMap: false,
  dataMap: state.dataMap.map((map: { [key: string]: any }) => {
    if (payload.id === map.id) {
      return payload;
    } else {
      return map;
    }
  }),
});

const mapDelete = (state: State, payload: any) => ({
  ...state,
  dataMap: state.dataMap.filter((map: { [key: string]: any }) => {
    return payload.id !== map.id;
  }),
  loadingDeleteMap: false,
});

const resetMap = (state: State) => ({
  ...state,
  loadingMap: false,
});

const mapReducer = (state = initState, action: any) => {
  switch (action.type) {
    case mapActionType.LOADING_MAP:
      return loadingMap(state);
    case mapActionType.LOADING_DELETE:
      return loadingDeleteMap(state);
    case mapActionType.RESET_MAP:
      return resetMap(state);
    case mapActionType.MAP_FETCHED:
      return mapFetched(state, action.payload);
    case mapActionType.MAP_FETCHED_SINGLE:
      return mapOneFetched(state, action.payload);
    case mapActionType.CREATE_MAP:
      return mapCreated(state, action.payload);
    case mapActionType.EDIT_MAP:
      return mapEdit(state, action.payload);
    case mapActionType.DELETE_MAP:
      return mapDelete(state, action.payload);
    default:
      return state;
  }
};

export default mapReducer;
