import React, { useState, useEffect } from "react";
/** Components */
import { Card, Form } from "../../components";
/** React Router */
import { Link, useNavigate } from "react-router-dom";
/** Action */
import { createUser, resetRegister } from "../../store/user/action";
/** React Redux */
import { useDispatch, useSelector } from "react-redux";
/** Swal */
import Swal from "sweetalert2";

interface Props {}

interface Inputs {
  type: string;
  placeholder: string;
  value: string;
  name: string;
  label: string;
  inputType: string;
}

const Register: React.FC<Props> = () => {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const registerUser = useSelector((state: any) => state.user);

  const { loadingRegister, registerSuccess } = registerUser;

  const [inputs, setInputs] = useState<Inputs[]>([
    {
      label: "Email",
      type: "email",
      value: "",
      name: "email",
      placeholder: "Input your email here",
      inputType: "input",
    },
    {
      label: "Username",
      type: "text",
      value: "",
      name: "username",
      placeholder: "Input your username here",
      inputType: "input",
    },
    {
      label: "Password",
      type: "password",
      value: "",
      name: "password",
      placeholder: "Input your password here",
      inputType: "input",
    },
  ]);

  useEffect(() => {
    if (loadingRegister) {
      Swal.fire({
        title: "Registering your account",
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });
    } else {
      Swal.close();
    }
  }, [loadingRegister]);

  const handleRegister = async () => {
    try {
      const body: { [key: string]: any } = {};

      inputs.forEach((input: Inputs) => {
        body[input.name] = input.value;
      });

      await dispatch(createUser(null, body));

      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });

      Toast.fire({
        icon: "success",
        title: "Your account has been registered",
      });

      dispatch(resetRegister());

      navigate("/login");
    } catch (error: any) {
      Swal.fire({
        icon: "error",
        title: "Register failed",
        text: error,
      });
    }
  };

  return (
    <div className="w-screen h-screen bg-gray-100">
      <div className="flex flex-wrap justify-center items-center w-full h-full">
        <div className="columns-md">
          <Card>
            <h1 className="text-center font-semibold text-xl">
              Welcome to Make Atlas
            </h1>
            <div className="mt-1">
              <Form
                inputs={inputs}
                setInputs={setInputs}
                action={handleRegister}
                btnName="REGISTER"
              />
            </div>
            <p className="text-center text-sm mt-3">
              Already have an account ?{" "}
              <Link
                className="font-semibold text-indigo-600 hover:text-indigo-500"
                to="/login"
              >
                Login Here !
              </Link>
            </p>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default Register;
