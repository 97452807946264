import React, { useState, useEffect } from "react";
/** Component */
import { CardPlace } from "../../components";
/** Icon */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
/** React Router Dom */
import {
  useNavigate,
  useParams,
  useLocation,
  Navigate,
  Link,
} from "react-router-dom";
/** React Redux */
import { useDispatch, useSelector } from "react-redux";
/** Action */
import { fetchPlaceDetail } from "../../store/place/action";
/** Image */
import LoadingGif from "../../assets/loading.gif";
/** Axios */
import axios from "axios";
/** Turf */
import { center } from "@turf/turf";

interface Props {}

const PlaceDetail: React.FC<Props> = () => {
  const location = useLocation();

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const place = useSelector((state: any) => state.place);

  const { username, slug, placename } = useParams();

  const { state }: any = location;

  const { loadingPlace, dataPlace } = place;

  const [data, setData] = useState<{ [key: string]: any }>({});

  const [newCenter, setNewCenter] = useState<number[]>([]);

  useEffect(() => {
    if (state === null) {
      (async () => {
        await dispatch(fetchPlaceDetail({ username, map: slug, placename }));
      })();
    }
  }, [state]);

  useEffect(() => {
    if (Object.keys(dataPlace).length > 0) {
      setData(dataPlace);
    } else if (state) {
      setData(state);
    }
  }, [state, dataPlace]);

  useEffect(() => {
    if (Object.keys(data).length > 0) {
      (async () => {
        try {
          const newCenter = calculateCenter(data);
          setNewCenter(newCenter);
        } catch (error) {
          console.log(error);
        }
      })();
    }
  }, [data]);

  const handleBackToMap = () => {
    navigate(`/${username}/${slug}`);
  };

  const calculateCenter = (place: any) => {
    return center(place).geometry.coordinates;
  };

  return (
    <div className="h-screen w-screen flex flex-wrap justify-center items-center bg-gray-100">
      {Object.keys(data).length > 0 && newCenter.length > 0 ? (
        <div className="w-2/3">
          <CardPlace background>
            <div className="p-10">
              <div
                className="flex items-center"
                onClick={() => handleBackToMap()}
              >
                <FontAwesomeIcon
                  icon={faChevronLeft}
                  color="black"
                  fontSize={"2rem"}
                />
                <span className="ml-4 text-lg font-semibold">Back to Map</span>
              </div>
              <div className="mt-10">
                <h1 className="text-2xl font-bold">{data.properties.name}</h1>
                <div className="flex flex-wrap w-full justify-between mt-3">
                  <div className="w-2/4 h-4/8">
                    <img
                      src={data.properties.image}
                      className="w-full rounded h-full"
                    />
                  </div>
                  <div className="w-2/5 h-4/8">
                    <img
                      onClick={() =>
                        window.open(data.properties.maps_url, "_blank")?.focus()
                      }
                      className="cursor-pointer"
                      src={`https://api.mapbox.com/styles/v1/mapbox/light-v10/static/pin-s+2a28a9(${newCenter[0]},${newCenter[1]})/${newCenter[0]},${newCenter[1]},14.5,0/400x400?before_layer=admin-0-boundary&access_token=${process.env.REACT_APP_MAPBOX_GL}`}
                    />
                  </div>
                </div>
              </div>
              <div className="mt-10">
                <a
                  href={data.properties.maps_url}
                  className="font-bold text-lg text-indigo-600 hover:text-indigo-500"
                  target="_blank"
                >
                  View On Google Maps
                </a>
              </div>
              {localStorage.getItem("username") === username && (
                <button
                  type="button"
                  className="py-1.5 px-4 border border-indigo-600 rounded mt-3 hover:bg-indigo-500 hover:text-white hover:transition-[background-color] 
                transition duration-500 ease-in-out hover:transition-[color] font-bold"
                  onClick={() =>
                    navigate(`/${username}/${slug}/edit/${placename}`, {
                      state: data,
                    })
                  }
                >
                  Edit Place
                </button>
              )}
            </div>
          </CardPlace>
        </div>
      ) : (
        <div className="flex flex-col">
          <img src={LoadingGif} />
          <h1 className="text-center mt-5 text-2xl font-bold">
            Fetching detail place
          </h1>
        </div>
      )}
    </div>
  );
};

export default PlaceDetail;
