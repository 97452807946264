import React, { useEffect, useState } from "react";
/** React Rotuer Dom */
import { Navigate, useParams, useNavigate } from "react-router-dom";
/** Action */
import { fetchOneMap } from "../../store/maps/action";
/** React Redux */
import { useDispatch, useSelector } from "react-redux";
/** Image */
import LoadingGif from "../../assets/loading.gif";

interface Props {
  children: React.ReactNode;
}

const ProtectedRouteMap: React.FC<Props> = ({ children }) => {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const map = useSelector((state: any) => state.map);

  const { username, slug } = useParams();

  const { singleMap, loadingMap } = map;

  useEffect(() => {
    if (!Object.keys(singleMap).length) {
      (async () => {
        try {
          await dispatch(fetchOneMap({ slug: slug }));
        } catch (error) {
          navigate("*");
        }
      })();
    }
  }, [singleMap]);

  const checkRender = () => {
    if (
      !singleMap.isPublic &&
      localStorage.getItem("id") == singleMap.ownerId
    ) {
      return <>{children}</>;
    } else if (!singleMap.isPublic) {
      return <Navigate to="*" replace />;
    } else {
      return <>{children}</>;
    }
  };

  return !loadingMap && Object.keys(singleMap).length > 0 ? (
    checkRender()
  ) : (
    <div className="w-screen h-screen">
      <div className="flex flex-col w-full h-full justify-center items-center">
        <img src={LoadingGif} />
        <h2 className="mt-3 font-semibold">Fetching a Map</h2>
      </div>
    </div>
  );
};

export default ProtectedRouteMap;
