import React, { useState, useEffect } from "react";
/** React Router */
import { useLocation, useParams, Link, useNavigate } from "react-router-dom";
/** React Redux */
import { useSelector, useDispatch } from "react-redux";
/** Component */
import { Card, Form, CustomMapGeoCoder } from "../../components";
/** Action */
import { fetchLayer } from "../../store/layers/action";
import {
  createPlace,
  fetchPlace,
  fetchPlaceDetail,
  editPlace,
  deletePlace,
} from "../../store/place/action";
/** FontAwesome */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
/** Swal */
import Swal from "sweetalert2";

interface Props {}

interface Inputs {
  type: string;
  placeholder: string;
  value: string | boolean | number | { [key: string]: any };
  name: string;
  label: string;
  inputType: string;
  options?: { [key: string]: any }[];
  subLabel?: { name: string; checked: boolean }[];
}

const FormPlace: React.FC<Props> = () => {
  const location = useLocation();

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const paramater = useParams();

  const { username, slug } = useParams();

  const { state }: any = location;

  const layers = useSelector((state: any) => state.layer);

  const places = useSelector((state: any) => state.place);

  const { dataLayers, layerFetched } = layers;

  const {
    loadingCreatePlace,
    isPlaceFetched,
    dataPlace,
    loadingEditedPlace,
    loadingDeletedPlace,
  } = places;

  const [purpose, setPurpose] = useState<string>();

  const [latLng, setLatLng] = useState<{ lat: number; lng: number }>({
    lat: 0,
    lng: 0,
  });

  const [placeId, setPlaceId] = useState<number>(0);

  const [inputs, setInputs] = useState<Inputs[]>([
    {
      type: "text",
      placeholder: "Search from map above",
      value: "",
      name: "location",
      label: "Locations (Lat, Lng)",
      inputType: "input",
    },
    {
      type: "text",
      placeholder: "Please input Place name",
      value: "",
      name: "name",
      label: "Place Name",
      inputType: "input",
    },
    {
      type: "text",
      placeholder: "Please input Place description",
      value: "",
      name: "description",
      label: "Place Description",
      inputType: "textarea",
    },
    {
      type: "text",
      placeholder: "Please input Goole Maps link",
      value: "",
      name: "maps_url",
      label: "Google Maps URL",
      inputType: "input",
    },
    {
      type: "text",
      placeholder: "Please input Image link",
      value: "",
      name: "image",
      label: "Place Image URL",
      inputType: "input",
    },
    {
      type: "",
      placeholder: "Select which layer you want to put place on it",
      value: 0,
      name: "layerId",
      label: "Select Layer",
      inputType: "select",
      options: [],
    },
    {
      type: "radio",
      placeholder: "",
      value: false,
      name: "isPublished",
      label: "Place Status",
      inputType: "radio",
      subLabel: [
        {
          name: "Public",
          checked: false,
        },
        {
          name: "Private",
          checked: false,
        },
      ],
    },
  ]);

  useEffect(() => {
    if (!isPlaceFetched) {
      (async () => {
        await dispatch(fetchPlace({ slug: slug, query: { name: "" } }));
      })();
    }
  }, [isPlaceFetched]);

  useEffect(() => {
    // Determine the form is for create or edit
    const { pathname } = location;
    const pathArray = pathname.split("/");
    setPurpose(pathArray[3] === "create-place" ? "create" : "edit");

    //fetch data
    (async () => {
      await dispatch(fetchLayer());
    })();
  }, []);

  useEffect(() => {
    if (layerFetched) {
      const newInputs = [...inputs];
      newInputs[5].options = dataLayers;
      newInputs[5].value = dataLayers[0].id;
      setInputs(newInputs);
    }
  }, [layerFetched]);

  useEffect(() => {
    if (loadingCreatePlace) {
      Swal.fire({
        title: "Please Wait",
        text: "Creating a new place for your map",
        didOpen: () => {
          Swal.showLoading();
        },
      });
    }
  }, [loadingCreatePlace]);

  useEffect(() => {
    if (loadingEditedPlace) {
      Swal.fire({
        title: "Please Wait",
        text: "Your place is being updating",
        didOpen: () => {
          Swal.showLoading();
        },
      });
    }
  }, loadingEditedPlace);

  useEffect(() => {
    if (loadingDeletedPlace) {
      Swal.fire({
        title: "Please Wait",
        text: "Your place is being Deleted",
        didOpen: () => {
          Swal.showLoading();
        },
      });
    }
  }, []);

  useEffect(() => {
    if (purpose === "edit" && state === null) {
      (async () => {
        await dispatch(
          fetchPlaceDetail({
            username,
            map: slug,
            placename: paramater.placename,
          })
        );
      })();
    }
  }, [state, purpose]);

  useEffect(() => {
    if (Object.keys(dataPlace).length > 0 || state !== null) {
      let properties: { [key: string]: any } = {};
      let geometry: { [key: string]: any } = {};

      if (Object.keys(dataPlace).length > 0) {
        properties = dataPlace.properties;
        geometry = dataPlace.geometry;
      } else if (state !== null) {
        properties = state.properties;
        geometry = state.geometry;
      }

      setPlaceId(properties.id);

      const newInputs: any = [...inputs];

      newInputs[0].value = geometry.coordinates.join(",");

      setLatLng({ lat: geometry.coordinates[0], lng: geometry.coordinates[1] });

      for (let i = 0; i < newInputs.length; i++) {
        for (let key in properties) {
          if (key === newInputs[i].name) {
            newInputs[i].value = properties[key];
            if (key === "isPublished" && properties[key] === true) {
              newInputs[i].subLabel[0].checked = true;
            } else if (key === "isPublished" && properties[key] === false) {
              newInputs[i].subLabel[1].checked = true;
            }
          }
        }
      }

      setInputs(newInputs);
    }
  }, [dataPlace, state]);

  const setLocations = (item: number[]) => {
    console.log(item);
    const newInputs = [...inputs];
    const locations = [...item];
    newInputs[0].value = locations.join(",");
    setInputs(newInputs);
  };

  const handleCreatePlace = async () => {
    try {
      const object: { [key: string]: any } = {};

      inputs.forEach((input: { [key: string]: any }) => {
        if (input.name === "location") {
          const locationArray = input.value.split(",");
          const lat = parseFloat(locationArray[1]);
          const lng = parseFloat(locationArray[0]);
          object[input.name] = {
            lat,
            lng,
          };
        } else {
          object[input.name] = input.value;
        }
      });

      await dispatch(createPlace(null, object));

      Swal.close();

      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
      });

      Toast.fire({
        icon: "success",
        title: "Place have been created",
      });

      navigate(`/${username}/${slug}`);
    } catch (error) {
      console.log(error);
    }
  };

  const handleEditPlace = async () => {
    try {
      const object: { [key: string]: any } = {};

      inputs.forEach((input: { [key: string]: any }) => {
        if (input.name === "location") {
          const locationArray = input.value.split(",");
          const lat = parseFloat(locationArray[1]);
          const lng = parseFloat(locationArray[0]);
          object[input.name] = {
            lat,
            lng,
          };
        } else {
          object[input.name] = input.value;
        }
      });

      await dispatch(editPlace({ id: placeId }, object));

      Swal.close();

      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
      });

      Toast.fire({
        icon: "success",
        title: "Place have been updated",
      });

      navigate(`/${username}/${slug}`);
    } catch (error) {
      console.log(error);
    }
  };

  const handleDeletePlace = async () => {
    try {
      const confirmation = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      });

      const { isConfirmed } = confirmation;

      // await dispatch(deletePlace({ id: placeId }));
      // Swal.close();
      // const Toast = Swal.mixin({
      //   toast: true,
      //   position: "top-end",
      //   showConfirmButton: false,
      //   timer: 3000,
      //   timerProgressBar: true,
      // });
      // Toast.fire({
      //   icon: "success",
      //   title: "Place have been updated",
      // });
      // navigate(`/${username}/${slug}`);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="w-scren py-12 bg-gray-100 relative">
      <Link
        to={`/${username}/${slug}`}
        className="absolute left-4 top-4 flex items-center"
      >
        <FontAwesomeIcon icon={faArrowLeft} color={"black"} fontSize={"25px"} />
        <span className="ml-2 font-bold text-black">Back</span>
      </Link>
      <div className="flex flex-wrap w-full h-full justify-center items-center">
        <div className="w-3/6">
          <Card>
            <h1 className="text-center font-bold text-2xl">
              {purpose === "create" ? "Create a Place" : "Update a Place"}
            </h1>
            <div className="pt-7 px-10 pb-10">
              <CustomMapGeoCoder
                action={setLocations}
                dataGeo={purpose === "edit" ? latLng : undefined}
              />
              <div className="my-3"></div>
              <Form
                inputs={inputs}
                setInputs={setInputs}
                action={
                  purpose === "create" ? handleCreatePlace : handleEditPlace
                }
                btnName={purpose === "create" ? "Save Map" : "Edit Map"}
              />
              {purpose === "edit" && (
                <div className="px-5 mt-2">
                  <button
                    className="
                    bg-white 
                    hover:bg-rose-500
                    hover:transition-[background-color] 
                    transition duration-700 ease-in-out 
                    py-1.5 
                    px-2 
                    rounded 
                    mt-3 
                    border 
                    border-rose-600
                    font-bold
                    tracking-wider
                    hover:text-white
                    hover:transition-[color]
                    w-full
                    text-rose-500"
                    onClick={() => handleDeletePlace()}
                  >
                    Delete Button
                  </button>
                </div>
              )}
            </div>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default FormPlace;
