import { mapActionType } from "./actionType";
import axios from "axios";
import { getToken } from "../../helper/localStograte";

const loadingMap = () => ({
  type: mapActionType.LOADING_MAP,
});

const loadingDelete = () => ({
  type: mapActionType.LOADING_DELETE,
});

const mapFetched = (payload: any) => ({
  type: mapActionType.MAP_FETCHED,
  payload,
});

const mapFetchedOne = (payload: any) => ({
  type: mapActionType.MAP_FETCHED_SINGLE,
  payload,
});

const mapCreated = (payload: any) => ({
  type: mapActionType.CREATE_MAP,
  payload,
});

const mapDelete = (payload: any) => ({
  type: mapActionType.DELETE_MAP,
  payload,
});

const mapEdit = (payload: any) => ({
  type: mapActionType.EDIT_MAP,
  payload,
});

export const resetMap = () => ({
  type: mapActionType.RESET_MAP,
});

export const fetchMap =
  (params?: any, body?: any) => (dispatch: any, getState: any) => {
    return new Promise(async (resolve, reject) => {
      try {
        await dispatch(loadingMap());

        const maps = await axios({
          url: `${process.env.REACT_APP_BASE_URL}/map`,
          method: "GET",
          headers: {
            token: `${getToken()}`,
          },
        });

        resolve(dispatch(mapFetched(maps.data.data)));
      } catch (error) {
        console.log(error);
      }
    });
  };

export const fetchOneMap =
  (params?: any, body?: any) => (dispatch: any, getState: any) => {
    return new Promise(async (resolve, reject) => {
      await dispatch(loadingMap());

      try {
        const map = await axios({
          url: `${process.env.REACT_APP_BASE_URL}/map/${params.slug}`,
          method: "get",
        });

        resolve(dispatch(mapFetchedOne(map.data.data)));
      } catch (error) {
        reject(error);
      }
    });
  };

export const createMap =
  (params?: any, body?: any) => (dispatch: any, getState: any) => {
    return new Promise(async (resolve, reject) => {
      try {
        await dispatch(loadingMap());

        const map = await axios({
          url: `${process.env.REACT_APP_BASE_URL}/map`,
          method: "post",
          headers: {
            token: `${getToken()}`,
          },
          data: body,
        });

        resolve(dispatch(mapCreated(map.data.data)));
      } catch (error: any) {
        reject(error.response.data.message);
      }
    });
  };

export const editMap =
  (params?: any, body?: any) => (dispatch: any, getState: any) => {
    return new Promise(async (resolve, reject) => {
      try {
        await dispatch(loadingMap());

        const map = await axios({
          url: `${process.env.REACT_APP_BASE_URL}/map/${params.id}`,
          method: "put",
          headers: {
            token: `${getToken()}`,
          },
          data: body,
        });

        resolve(dispatch(mapEdit(map.data.data)));
      } catch (error: any) {
        reject(error.response.data.message);
      }
    });
  };

export const deleteMap =
  (params?: any, body?: any) => (dispatch: any, getState: any) => {
    return new Promise(async (resolve, reject) => {
      try {
        await dispatch(loadingDelete());

        const map = await axios({
          url: `${process.env.REACT_APP_BASE_URL}/map/${params.id}`,
          method: "delete",
          headers: {
            token: `${getToken()}`,
          },
        });

        resolve(dispatch(mapDelete(map.data.data)));
      } catch (error) {
        console.log(error);
      }
    });
  };
