import React, { useState, useEffect, useRef } from "react";
/** Map */
import mapboxgl from "mapbox-gl";
/** Turf */
import { center, points, bbox } from "@turf/turf";

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_GL
  ? process.env.REACT_APP_MAPBOX_GL
  : "";

interface Props {
  height: string;
  data: any;
  dataGeo: any;
  setDataGeo: React.Dispatch<React.SetStateAction<any>>;
}

interface MapData {
  style: string;
  containerStyle: {
    width: string;
    height: string;
  };
  zoom: number;
}

const CustomMap: React.FC<Props> = ({ height, data, dataGeo, setDataGeo }) => {
  const mapContainer = useRef<any>("");
  const [mapData, setMapData] = useState<MapData>({
    style: "mapbox://styles/mapbox/light-v10",
    containerStyle: {
      width: "100%",
      height: height,
    },
    zoom: 15,
  });
  const [map, setMap] = useState<mapboxgl.Map>();

  useEffect(() => {
    setMap(
      new mapboxgl.Map({
        container: mapContainer.current,
        style: mapData.style,
        doubleClickZoom: false,
      })
    );
  }, []);

  useEffect(() => {
    map?.dragRotate.disable();
    map?.touchZoomRotate.disableRotation();

    if (data.features.length > 0) {
      map?.on("load", () => {
        map.addSource("places-source", {
          type: "geojson",
          data: data,
        });

        map.addSource("places-active", {
          type: "geojson",
          data: {
            type: "FeatureCollection",
            features: [
              {
                type: "Feature",
                properties: {},
                geometry: {
                  type: "Point",
                  coordinates: [-76.53063297271729, 39.18174077994108],
                },
              },
            ],
          },
        });

        map.addLayer({
          id: "places-layer",
          type: "circle",
          source: "places-source",
          paint: {
            "circle-color": "#2E97FC",
            "circle-radius": 10,
            "circle-stroke-width": 5,
            "circle-stroke-opacity": 0.05,
          },
        });

        map.on("click", "places-layer", (e: any) => {
          handleActivePlace(e.features[0].properties.id);
        });

        map?.on("mouseenter", "places-layer", () => {
          map.getCanvas().style.cursor = "pointer";
        });

        map?.on("mouseleave", "places-layer", () => {
          map.getCanvas().style.cursor = "";
        });
      });

      const fitBounds: any = bbox(data);

      map?.fitBounds(fitBounds, { padding: 200 });
    }
  }, [map]);

  useEffect(() => {
    const newDataGeo = { ...dataGeo };

    const { features } = dataGeo;

    const place = features.filter((feature: { [key: string]: any }) => {
      return feature.isActive;
    });

    if (place.length > 0) {
      newDataGeo.features = place[0];
      focusPlace(place[0], newDataGeo);
    }
  }, [dataGeo]);

  const handleActivePlace = (id: number) => {
    const { features } = dataGeo;

    const newFeatures = features.map((feature: { [key: string]: any }) => {
      if (id === feature.properties.id) {
        return {
          ...feature,
          isActive: true,
        };
      } else {
        return {
          ...feature,
          isActive: false,
        };
      }
    });

    const newDataGeo = { ...dataGeo };

    newDataGeo.features = newFeatures;

    setDataGeo(newDataGeo);
  };

  const focusPlace = (place: any, newDataGeo: any) => {
    const newCenter: any = center(place).geometry.coordinates;

    map?.flyTo({
      center: newCenter,
      essential: true,
      zoom: mapData.zoom,
    });

    const geoJsonSoruce: any = map?.getSource("places-active");

    geoJsonSoruce.setData(newDataGeo);

    map?.on("move", () => {
      map?.addLayer({
        id: place.properties.name,
        type: "circle",
        source: "places-active",
        paint: {
          "circle-color": "#10B981",
          "circle-radius": 10,
          "circle-stroke-width": 5,
          "circle-stroke-opacity": 0.05,
        },
      });
    });
  };

  return (
    <div ref={mapContainer} style={{ width: "100%", height: height }}></div>
  );
};

export default CustomMap;
